import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  addSailToCart, 
  deleteSailFromCart, 
  setPostcode, 
  updateSailInCart,
  setShippingInformation,
  clearCart,
  clearStripePaymentIntent,
  applySelfInstallDiscount,
  removeSelfInstallDiscount,
  setCouponCode,
  setCouponCodeResult,
  setCouponCodeFetching,
  setCouponCodeError,
  setShippingAddress,
  setSelectedCouponCode,
  setSelectedFreeWarrantyCouponCode,
  setSelectedNoInstallationKitCouponCode,
  clearSelectedCouponCode,
  clearSelectedFreeWarrantyCouponCode,
  clearSelectedNoInstallationKitCouponCode,
  clearExtendedWarranty,
  addExtendedWarranty,
} from './actions'
import { CartContext } from './context'
import WhatHappensNextPopup from '../OrderContentBlock/WhatHappensNextPopup'

const { useReducer, useState, useRef } = React

const CartLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)
  const [showWhatHappensNextPopup, setShowWhatHappensNextPopup] = useState(false)
  const fetchCouponCodeRef = useRef({timeout: 0}).current

  const maybeShowWhatHappensNextPopup = () => {
    if (localStorage.getItem('hasSeenWhatHappensNextVideo') != '1'){
      localStorage.setItem('hasSeenWhatHappensNextVideo', '1')
      setShowWhatHappensNextPopup(true)
    }
  }

  const fetchCouponCode = (couponCode) => {
    
    dispatch(setCouponCodeError(null))
    dispatch(setCouponCodeResult(null))

    if (!couponCode)
      return
    dispatch(setCouponCodeFetching(true))
  

    fetch(
      process.env.GATSBY_STRIPE_API + '/coupon-code/' + couponCode
    )
      .then(response => {
        return response.json()
          .then(result => {
            dispatch(setCouponCodeFetching(false))
            if (response.status != 200){
              throw new Error(result.message || 'Unable to fetch coupon code information.')
            }

            if (result['couponCode']?.['coupon']?.['type'] == 'FREEWARRANTY'){
              dispatch(setSelectedFreeWarrantyCouponCode(result))
              return
            }
            if (result['couponCode']?.['coupon']?.['type'] == 'NOINSTALLATIONKIT'){
              dispatch(setSelectedNoInstallationKitCouponCode(result))
              return
            }

            dispatch(setCouponCodeResult(result))

            if (result.couponCode){
              dispatch(setSelectedCouponCode(result))
            }

          })
      })
      .catch(error => {
        console.error(error)
        dispatch(setCouponCodeFetching(false))
        dispatch(setCouponCodeError(error))
        throw error
      })
  }

  var couponDiscount = 0
  var couponPercentDiscount = 0
  var coupon = reducerState.selectedCouponCodeResult?.couponCode?.coupon
  if (coupon){
    if (coupon.amount_off){
      couponDiscount = coupon.amount_off
    } else if (coupon.percent_off) {
      couponPercentDiscount = coupon.percent_off
    }
  }

  return <CartContext.Provider
    value={{
      addSailToCart: (sail) => {
        dispatch(addSailToCart(sail))
      },
      deleteSailFromCart: sail => {
        dispatch(deleteSailFromCart(sail))
      },
      updateSailInCart: (sail) => {
        dispatch(updateSailInCart(sail))
      },
      setPostcode: postcode => {
        dispatch(setPostcode(postcode))
      },
      setShippingInformation: shippingInfo => {
        dispatch(setShippingInformation(shippingInfo))
      },
      clearCart: () => {
        dispatch(clearCart())
      },
      clearStripePaymentIntent: () => {
        dispatch(clearStripePaymentIntent())
      },
      applySelfInstallDiscount: () => {
        
        // Its requested that the what happens next popup should show 6 sections
        // after this is pressed, if it has never been seen before
        
        setTimeout(maybeShowWhatHappensNextPopup, 6000)

        dispatch(applySelfInstallDiscount())
      },
      removeSelfInstallDiscount: () => dispatch(removeSelfInstallDiscount()),
      dispatch,
      reducerState,
      showWhatHappensNextPopup: () => {
        localStorage.setItem('hasSeenWhatHappensNextVideo', '1')
        setShowWhatHappensNextPopup(true)
      },
      updateCouponCode: (couponCode) => {

        dispatch(setCouponCode(couponCode))
        clearTimeout(fetchCouponCodeRef.timeout)
        fetchCouponCodeRef.timeout = setTimeout(() => fetchCouponCode(couponCode), 200)
      },
      // TODO SHould probably remove these two and infer from the result
      couponDiscount,
      couponPercentDiscount,
      setShippingAddress: address => dispatch(setShippingAddress(address)),
      clearSelectedCouponCode: () => dispatch(clearSelectedCouponCode()),
      clearSelectedFreeWarrantyCouponCode: () => dispatch(clearSelectedFreeWarrantyCouponCode()),
      clearSelectedNoInstallationKitCouponCode: () => dispatch(clearSelectedNoInstallationKitCouponCode()),
      clearExtendedWarranty: () => dispatch(clearExtendedWarranty()),
      addExtendedWarranty: () => dispatch(addExtendedWarranty()),
    }}
  >
    <>
      {children}
      <WhatHappensNextPopup
        open={showWhatHappensNextPopup}
        onClose={() => {
          setShowWhatHappensNextPopup(false)
        }}
      />
    </>
  </CartContext.Provider>
}

export default CartLogic
