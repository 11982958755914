

export const defaultState = {
  attachments: {},
}

const reducer = (state = defaultState, action) => {

  switch (action.type){
  case 'ADD_ATTACHMENT':
  case 'UPDATE_ATTACHMENT':
    var { attachment } = action
    var newState = Object.assign({}, state)
    var existingAttachment = newState.attachments[attachment.id]
    if (JSON.stringify(attachment) == JSON.stringify(existingAttachment)){
      // Nothing has changed, so return the existing state
      return state
    }
    newState.attachments[attachment.id] = attachment
    return newState
  case 'REMOVE_ATTACHMENT':
    var { attachment } = action
    var newState = Object.assign({}, state)
    if (!state.attachments[attachment.id]){
      // Nothing changed
      return state
    }
    var newState = Object.assign({}, state)
    newState.attachments = Object.assign({}, newState.attachments)
    delete newState.attachments[attachment.id]
    return newState
  case 'CLEAR_ATTACHMENTS': 
    return {
      attachments: {},
    }
  case 'SET_LOADING':
    var { value } = action
    var newState = Object.assign({}, state)
    if (newState.loading == value){
      // Nothing changed
      return state
    }
    newState.loading = value
    return newState
  case 'SET_ERROR':
    var { error } = action
    var newState = Object.assign({}, state)
    newState.error = error
    return newState
  case 'SET_ATTACHMENT_DELETING':
    var { 
      attachment,
      value, 
    } = action
      
    var existingAttachment = state.attachments[attachment.id]
    if (!existingAttachment)
      return state
      // Nothing has changed
    if (existingAttachment.isDeleting == value)
      return state

    var existingAttachmentClone = Object.assign(
      {}, 
      existingAttachment,
      { isDeleting: value, }
    )
    var attachmentsClone = Object.assign({}, state.attachments)
    attachmentsClone[existingAttachmentClone.id] = existingAttachmentClone
    var newState = Object.assign({}, state)
    newState.attachments = attachmentsClone
    return newState
  case 'DELETE_ATTACHMENT':
    var { attachment } = action
    var attachmentsClone = Object.assign({}, state.attachments)
    delete attachmentsClone[attachment.id]
    var newState = Object.assign({}, state)
    newState.attachments = attachmentsClone
    return newState
  case 'SET_ATTACHMENT_DELETE_ERROR':
    var { attachment, error } = action
    var existingAttachment = state.attachments[attachment.id]
    if (!existingAttachment)
      return state
      
    var existingAttachmentClone = Object.assign(
      {}, 
      existingAttachment,
      { error }
    )
    var attachmentsClone = Object.assign({}, state.attachments)
    attachmentsClone[existingAttachmentClone.id] = existingAttachmentClone
    var newState = Object.assign({}, state)
    newState.attachments = attachmentsClone
    return newState
  default: 
  }

  return state
}

export default reducer
