import * as React from 'react'
import Popup from '../Popup'
import * as styles from './HelpPopup.styles.css'
import { formatUrlToEmbeddedUrl } from './../VideoPopup/util'


const WhatHappensNextPopup = props => {
  const { onClose } = props
  const url = formatUrlToEmbeddedUrl('https://vimeo.com/996505313')

  return <Popup
    className={styles.popup}
    {...props}
  >
    <h2>What happens next</h2>
    <div className={styles.content}>
      <div className={styles.videoContainer}>
        <iframe 
          width="100%"
          height="100%"
          src={url.toString()}
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen
        ></iframe>
      </div>
    </div>

    <button className="primary" onClick={onClose}>Okay</button>

  </Popup>
}

export default WhatHappensNextPopup
