export const addAttachment = function(attachment){
  return {
    type: 'ADD_ATTACHMENT',
    attachment,
  }
}

export const updateAttachment = function(attachment){
  return {
    type: 'UPDATE_ATTACHMENT',
    attachment,
  }
}

export const removeAttachment = function(attachment){
  return {
    type: 'REMOVE_ATTACHMENT',
    attachment,
  }
}

export const clearAttachments = function(){
  return {
    type: 'CLEAR_ATTACHMENTS',
  }
}

export const setIsLoading = function(value){
  return {
    type: 'SET_LOADING',
    value,
  }
}

export const setError = function(error){
  return {
    type: 'SET_ERROR',
    error,
  }
}

export const setAttachmentDeleting = function(attachment, value){
  return {
    type: 'SET_ATTACHMENT_DELETING',
    attachment,
    value,
  }
}


export const setAttachmentDeleteError = function(attachment, error){
  return {
    type: 'SET_ATTACHMENT_DELETE_ERROR',
    attachment,
    error,
  }
}

export const deleteAttachment = function(attachment){
  return {
    type: 'DELETE_ATTACHMENT',
    attachment,
  }
}