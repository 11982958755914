

export const deleteNote = function(note){
  return {
    type: 'DELETE_NOTE',
    note,
  }
}

export const updateNote = function(note){
  return {
    type: 'UPDATE_NOTE',
    note,
  }
}

export const setNoteLoading = function(noteId, value){
  return {
    type: 'SET_NOTE_LOADING',
    noteId,
    value, 
  }
}

export const setNoteError = function(noteId, error){
  return {
    type: 'SET_NOTE_ERROR',
    noteId,
    error, 
  }
}


export const setNoteUpdating = function(noteId, value){
  return {
    type: 'SET_NOTE_UPDATING',
    noteId,
    value, 
  }
}

export const setNoteUpdateError = function(noteId, error){
  return {
    type: 'SET_NOTE_UPDATE_ERROR',
    noteId,
    error, 
  }
}