import * as React from 'react'
import classNames from 'classnames'
import { animated } from '@react-spring/web'
import CrossIcon from '../icons/Cross'
import * as styles from './Modal.styles.css'
import { vars } from './../../styles/global.css'
import { usePopUpIntoView } from './../../util/animations'

const { useRef } = React

const Modal = props => {

  const {
    onClose,
    children,
    className,
    contentContainerClassName,
  } = props

  const elementRef = useRef()
  const popIntoViewStyles = usePopUpIntoView(elementRef)

  // Prevent tapping on the content from closing it
  const onContainerClick = (e) => {
    e.stopPropagation()
  }

  return <animated.div 
    ref={elementRef}
    style={{
      ...popIntoViewStyles,
    }}
    onClick={onContainerClick}
    className={classNames(styles.container, className)}
  >
    <div className={classNames(styles.contentContainer, contentContainerClassName)}>
      {children}
    </div>
    <button className={styles.closeButton} onClick={onClose}>
      <CrossIcon 
        width={10}
        height={10}
        color={vars.colors.primary.dark}
      />
    </button>
  </animated.div>
}

export default Modal
