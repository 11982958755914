import * as React from 'react'
import { defaultState } from './reducer'

const { createContext, useContext } = React

export const NotesContext = createContext({ 
  reducerState: defaultState, 
})

export const useNotesContext = () => useContext(NotesContext)
