
export const defaultState = {}

const reducer = (state = defaultState, action) => {
  switch (action.type){
  case 'SET_ADDRESS_LOOKUP_RESULT':
    state = Object.assign({}, state)
    state.result = action.result
    return state
  case 'SET_ADDRESS_LOOKUP_FETCHING':
    state = Object.assign({}, state)
    state.fetching = action.value
    return state
  case 'SET_ADDRESS_LOOKUP_ERROR':
    state = Object.assign({}, state)
    state.error = action.error
    return state
  case 'CLEAR_ADDRESS_LOOKUP':
    state = Object.assign({}, state)
    state.result = null
    return state
  default:
    return state
  }
}

export default reducer
