


const roundUpTo2Dp = function(val){
  val = Number(val)
  val = Math.ceil(Number(Number(val * 100).toFixed(2)))
  return val / 100
}

const roundTo3Dp = function(val){
  val = Number(val)
  return Math.round(val * 1000) / 1000
}

module.exports = {
  roundUpTo2Dp,
  roundTo3Dp,
}
