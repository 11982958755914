

export const setResult = function(result){
  return {
    type: 'SET_ADDRESS_LOOKUP_RESULT',
    result: result,
  }
}

export const setFetching = function(value){
  return {
    type: 'SET_ADDRESS_LOOKUP_FETCHING',
    value: value,
  }
}

export const setError = function(error){
  return {
    type: 'SET_ADDRESS_LOOKUP_ERROR',
    error: error,
  }
}

export const clearAddressLookup = function(){
  return {
    type: 'CLEAR_ADDRESS_LOOKUP',
  }
}