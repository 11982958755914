import * as React from 'react'
import CartLogic from '../CartLogic'
import UserLogic from '../UserLogic'
import OrdersLogic from '../OrdersLogic'
import AttachmentsLogic from '../AttachmentsLogic'
import NotesLogic from '../NotesLogic'
import AddressValidationService from './../AddressValidationService'
import VideoPopupLogic from '../VideoPopupLogic'

const App = props => {
  const { children } = props
  return <>
    <CartLogic>
      <UserLogic>
        <OrdersLogic>
          <AttachmentsLogic>
            <NotesLogic>
              <AddressValidationService>
                <VideoPopupLogic>
                  {children}
                </VideoPopupLogic>
              </AddressValidationService>
            </NotesLogic>
          </AttachmentsLogic>
        </OrdersLogic>
      </UserLogic>
    </CartLogic>
  </>
}

export default App
