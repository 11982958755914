

import * as React from 'react'
import { 
  useSpring,
  config,
} from '@react-spring/web'

const { useEffect } = React


const usePopIntoView = options => {
  const {
    from, 
    to,
  } = options


  return (elementRef, options = {}) => {
    const { delay = 200 } = options
    const [popUpSpring, popupSpringsApi] = useSpring(() => ({ from }))

    useEffect(() => { 
      
      const element = elementRef.current
      if (!element)
        return

      const callback = (entries) => {

        const isVisible = entries.find(entry => {
          return entry.intersectionRatio > 0
        })
        if (isVisible) {
          observer.unobserve(element)
          // As requested delay animation by a bit of time
          setTimeout(
            () =>  {
              if (elementRef.current && popupSpringsApi){
                popupSpringsApi.start(to)
              }
            }, 
            delay
          )
        }
      }
      const observer = new IntersectionObserver(callback)

      observer.observe(element)

      return () => {
        observer.disconnect()
      }

    }, [elementRef.current])

    return popUpSpring
  }
}


export const usePopUpIntoView = usePopIntoView({
  from: { y: 100, opacity: 0.85, },
  to: { y: 0, opacity: 1, }
})

export const usePopRightIntoView = usePopIntoView({
  from: { x: -100, opacity: 0.85, },
  to: { x: 0, opacity: 1, }
})

export const usePopLeftIntoView = usePopIntoView({
  from: { x: 100, opacity: 0.85, },
  to: { x: 0, opacity: 1, }
})


export const useScalePopInIntoView = usePopIntoView({
  from: { 
    scale: 0, 
    config: { tension: 180, friction: 14, mass: 3 },
  },
  to: { scale: 1 },
})

export const useScalePopRightIntoView = usePopIntoView({
  from: { 
    x: -100,
    scale: 0, 
    config: { tension: 180, friction: 14, mass: 3 },
  },
  to: { scale: 1, x: 0 },
  delay: 0,
})


export const usePopOnHover = (elementRef) => {

  const from = { 
    scale: 1, 
    config: config.gentle,
  }
  const to = { scale: 1.05, config: config.gentle }
  const [hoverSpring, hoverSpringApi] = useSpring(() => ({ 
    from,
  }))
  
  useEffect(() => { 

    const onMouseOver = () => {
      hoverSpringApi.start({
        ...to,
        immediate: false,
      })
    }

    const onMouseOut = () => {
      hoverSpringApi.start(from)
    }
    
    elementRef.current.addEventListener('mouseover', onMouseOver)
    elementRef.current.addEventListener('mouseout', onMouseOut)

  }, [])

  return hoverSpring
}


