import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  setUser,
  setJwt,
  clearUser,
} from './actions'
import { UserContext } from './context'

const { useReducer } = React

const UserLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  return <UserContext.Provider
    value={{
      setUser: (user) => {
        dispatch(setUser(user))
      },
      setJwt: (jwt) => {
        dispatch(setJwt(jwt))
      },
      clearUser: () => {
        dispatch(clearUser())
      },
      dispatch,
      reducerState,
    }}
  >
    {children}
  </UserContext.Provider>
}

export default UserLogic
