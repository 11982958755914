import * as React from 'react'
import { 
  useSpring,
  animated,
} from '@react-spring/web'
import * as styles from './styles.css'
import EndOfDOMContainer from '../EndOfDOMContainer'
import Modal from './Modal'

const { useEffect } = React

const Popup = props => {

  const { 
    children,
    onBackgroundClick,
    onClose,
    className,
    open = true,
    contentContainerClassName,
  } = props


  const [fadeInSpringStyles, fadeInSpringApi] = useSpring(() => ({ 
    from: { opacity: 0, },
    to: { opacity: 0.25, } 
  }))
  useEffect(() => { 
    //if (!open)
    //  return
    fadeInSpringApi.start()
  }, [open])

  if (!open){
    return null
  }


  return <EndOfDOMContainer>
    <div 
      className={styles.outerContainer} 
      onClick={onBackgroundClick}
      
    >
      <animated.div 
        className={styles.opaqueBackground}
        style={{
          ...fadeInSpringStyles
        }}
      ></animated.div>
      <div className={styles.container}>
        <Modal 
          onClose={onClose}
          className={className}
          contentContainerClassName={contentContainerClassName}
        >
          {children}
        </Modal>
      </div>
    </div>
  </EndOfDOMContainer>
}

export default Popup