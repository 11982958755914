import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  addOrder,
  setOrder,
  clearOrders,
  updateOrder,
  updateSailProps,
  updateSail,
} from './actions'
import { OrdersContext } from './context'
import { Order, Sail } from '../CartLogic/types'
import { UpdateOrderProps, UpdateSailParams } from './types'

const { useReducer } = React

const OrdersLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  return <OrdersContext.Provider
    value={{
      addOrder: (order: Order) => {
        dispatch(addOrder(order))
      },
      setOrder: (order: Order) => {
        dispatch(setOrder(order))
      },
      updateOrder: (orderId: number, order: UpdateOrderProps) => {
        dispatch(updateOrder(orderId, order))
      },
      updateSail: (params: UpdateSailParams, sail: Sail) => {
        dispatch(updateSail(params, sail))
      },
      updateSailProps: (params: UpdateSailParams, sail: Sail) => {
        dispatch(updateSailProps(params, sail))
      },
      clearOrders: () => {
        dispatch(clearOrders())
      },
      dispatch,
      reducerState,
    }}
  >
    {children}
  </OrdersContext.Provider>
}

export default OrdersLogic
