import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  updateNote,
  deleteNote,
  setNoteLoading,
  setNoteError,
  setNoteUpdateError,
  setNoteUpdating,
} from './actions'
import { NotesContext } from './context'

const { useReducer } = React

const NotesLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  return <NotesContext.Provider
    value={{
      updateNote: (note) => {
        dispatch(updateNote(note))
      },
      deleteNote: (note) => {
        dispatch(deleteNote(note))
      },
      setNoteLoading: (noteId, value) => {
        dispatch(setNoteLoading(noteId, value))
      },
      setNoteError: (noteId, error) => {
        dispatch(setNoteError(noteId, error))
      },
      setNoteUpdating: (noteId, value) => {
        dispatch(setNoteUpdating(noteId, value))
      },
      setNoteUpdateError: (noteId, error) => {
        dispatch(setNoteUpdateError(noteId, error))
      },
      dispatch,
      reducerState,
    }}
  >
    {children}
  </NotesContext.Provider>
}

export default NotesLogic
