import * as React from 'react'
import { Order, Sail } from '../CartLogic/types'
import { UpdateOrderProps, UpdateSailParams } from './types'
import { defaultState, OrderReducerState } from './reducer'

const { createContext, useContext } = React


type OrderContextResponse = {
  dispatch:  () => void,
  reducerState: OrderReducerState,
  addOrder: (order: Order) => void,
  setOrder: (order: Order) => void,
  updateOrder: (orderId: string, order: UpdateOrderProps) => void,
  updateSail: (params: UpdateSailParams, sail: Sail) => void,
  clearOrders: () => void,
}

export const OrdersContext = createContext({ 
  reducerState: defaultState, 
})

export const useOrdersContext = (): OrderContextResponse => useContext(OrdersContext)
