import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  setResult,
  setFetching,
  setError,
  clearAddressLookup,
} from './actions'
import { AddressValidationServiceContext } from './context'

const { useReducer } = React

const AddressValidationService = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  const doAddressValidationLookup = (params) => {
    
    const {
      line1,
      line2,
      city,
      state,
      postcode,
      country
    } = params

    if (country != 'AU'){
      throw new Error('Country must be AU.')
    }

    dispatch(setError(null))
    dispatch(setResult(null))
    dispatch(setFetching(true))
  
    /*curl -X POST -d '{
      "address": {
        "regionCode": "US",
        "locality": "Mountain View",
        "addressLines": ["1600 Amphitheatre Pkwy"]
      }
    }' \
    -H 'Content-Type: application/json' \
    "https://addressvalidation.googleapis.com/v1:validateAddress?key="*/
    // Info at https://developers.google.com/maps/documentation/address-validation/requests-validate-address
    // https://developers.google.com/maps/documentation/address-validation/overview
    /*curl -L -X GET 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=89%20goodwins%20road&types=address&components=country:au&region=au&key=AIzaSyBBsAYFYqwrDXuHSHqxtl-0AchWSowlPy4'*/
    return fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.GATSBY_GOOGLE_API_KEY}`,
      {
        method: 'post',
        body: JSON.stringify({
          address: {
            regionCode: 'AU',
            postalCode: postcode,
            locality: state,
            sublocality: city,
            addressLines: [
              line1,
              line2,
            ],
          }
        })
      }
    )
      .then(response => {
        return response.json()
          .then(result => {
            dispatch(setFetching(false))

            if (response.status != 200){
              throw new Error(result.error?.message || 'Non 200 status code returned from Google\'s validate address endpoint.')
            }
            if (result.error){
              throw new Error(result.error?.message || 'Google\'s validate address endpoint returned an error.')
            }
            dispatch(setResult(result))
            return result
          })
      })
      .catch(error => {
        console.error(error)
        dispatch(setFetching(false))
        dispatch(setError(error))
        throw error
      })
  }
  
  return <AddressValidationServiceContext.Provider
    value={{
      dispatch,
      reducerState,
      doAddressValidationLookup,
      clearAddressLookup: () => dispatch(clearAddressLookup()),
    }}
  >
    <>
      {children}
    </>
  </AddressValidationServiceContext.Provider>
}

export default AddressValidationService
