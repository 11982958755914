import * as React from 'react'
import { navigate } from 'gatsby'
import reducer, { defaultState } from './reducer'
import {
  openVideoPopup,
  closeVideoPopup,
} from './actions'
import { VideoPopupContext } from './context'

const { useReducer } = React

const VideoPopupLogic = ({ children }) => {
  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  return (
    <VideoPopupContext.Provider
      value={{
        openVideoPopup: (videoUrl) => {
          dispatch(openVideoPopup(videoUrl))
        },
        closeVideoPopup: () => {
          // This is a bit of a hack, but on the intro page after closing the
          // video it should redirect the user to the home page. 
          // I don't imagine this being needed more than once so I'm not adding
          // it as a config option on Contentful for now.
          if (window.location.pathname.startsWith('/intro')){
            navigate('/')
          }
          dispatch(closeVideoPopup())
        },
        dispatch,
        reducerState,
      }}
    >
      {children}
    </VideoPopupContext.Provider>
  )
}

export default VideoPopupLogic
