/**
 * @fileOverview Do not modify this file, its generated by running the 
 * script convert-csv-to-price-list.js 
 */

const priceList = [
  // When this colour is chosen and if both ends are 'posts' then (2x length between structures)+(60% sail width)+(2(sail height-500mm)). For any other structures combination (so anything other than posts-to-posts): 'Fixed' rule: (2x length between structures)+(sail width)+(2x sail height)+1800mm. And 'Floating' rule: (2x length between structures)+(sail width)+(2x sail height)+1800mm
  {
    label: "Polyester cord (black) rope #1",
    labelMandarin: "聚酯绳（黑色）绳#1",
    key: "_6MM_YACHT_CORD_BLACK_ROPE_1",
    price: 484.544,
    bag: "Box 2",
    weight: 0.023,
  },
  // If both ends are 'posts' and 'Floating' rule: (sail height - 500mm)+(40% sail width)+(length between structures x 1.4); For any other structures combination (so anything other than posts-to-posts): and  'Floating' rule: (sail height-500mm) + (60% sail width) + (length between structures x 1.4).
  {
    label: "Polyester cord (black) rope #2",
    labelMandarin: "聚酯绳（黑色）绳#2",
    key: "_6MM_YACHT_CORD_BLACK_ROPE_2",
    price: 484.544,
    bag: "Box 2",
  },
  // When this colour is chosen and if both ends are 'posts' then (2x length between structures)+(60% sail width)+(2(sail height-500mm)). For any other structures combination (so anything other than posts-to-posts): 'Fixed' rule: (2x length between structures)+(sail width)+(2x sail height)+1800mm. And 'Floating' rule: (2x length between structures)+(sail width)+(2x sail height)+1800mm
  {
    label: "Polyester cord (white) rope #1",
    labelMandarin: "聚酯绳（白色）绳#1",
    key: "_6MM_YACHT_CORD_WHITE_ROPE_1",
    price: 484.544,
    bag: "Box 2",
    weight: 0.023,
  },
  // If both ends are 'posts' and 'Floating' rule: (sail height - 500mm)+(40% sail width)+(length between structures x 1.4); For any other structures combination (so anything other than posts-to-posts): and  'Floating' rule: (sail height-500mm) + (60% sail width) + (length between structures x 1.4).
  {
    label: "Polyester cord (white) rope #2",
    labelMandarin: "聚酯绳（白色）绳#2",
    key: "_6MM_YACHT_CORD_WHITE_ROPE_2",
    price: 484.544,
    bag: "Box 2",
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For monument coloured sails.
  {
    label: "Small clamp - Monument",
    labelMandarin: "小夹子 #6",
    key: "SMALL_CLAMP_MONUMENT",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For Western Myall coloured sails.
  {
    label: "Small clamp - Western Myall",
    labelMandarin: "小夹子 #5",
    key: "SMALL_CLAMP_WESTERN_MYALL",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For windspray coloured sails.
  {
    label: "Small clamp - Windspray",
    labelMandarin: "小夹子 #4",
    key: "SMALL_CLAMP_WINDSPRAY",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For shale grey coloured sails.
  {
    label: "Small clamp - Shale Grey",
    labelMandarin: "小夹子 #3",
    key: "SMALL_CLAMP_SHALE_GREY",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For classic cream coloured sails.
  {
    label: "Small clamp - Classic cream",
    labelMandarin: "小夹子 #1",
    key: "SMALL_CLAMP_CLASSIC_CREAM",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // If fixed, then 2x (number of sheeves - 1), if floating then 2x (number of sheeves-2).  For lexicon quarter coloured sails.
  {
    label: "Small clamp - Lexicon Qtr",
    labelMandarin: "小夹子 #2",
    key: "SMALL_CLAMP_LEXICON_QTR",
    price: 1881.4499999999998,
    bag: "Box 1",
    weight: 0.018,
  },
  // 3 for a 'fixed' sail, 2 if 'floating' sail
  {
    label: "Y-shackle",
    labelMandarin: "Y型卸扣",
    key: "Y_SHACKLE",
    price: 1145.80305,
    bag: "Box 2",
    weight: 0.011,
  },
  // 3 per monument sail + 3 if floating monument
  {
    label: "Large clamp - Monument",
    labelMandarin: "大夹子 #6",
    key: "LARGE_CLAMP_MONUMENT",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // 3 per Western Myall sail + 3 if floating Western Myall sail
  {
    label: "Large clamp - Western Myall",
    labelMandarin: "大夹子 #5",
    key: "LARGE_CLAMP_WESTERN_MYALL",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // 3 per windspray sail + 3 per floating windspray sail
  {
    label: "Large clamp - Windspray",
    labelMandarin: "大夹子 #4",
    key: "LARGE_CLAMP_WINDSPRAY",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // 3 per shale grey sail + 3 per floating shale grey sail
  {
    label: "Large clamp - Shale grey",
    labelMandarin: "大夹子 #3",
    key: "LARGE_CLAMP_SHALE_GREY",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // 3 per classic cream sail + 3 per floating classic cream sail
  {
    label: "Large clamp - Classic cream",
    labelMandarin: "大夹子 #1",
    key: "LARGE_CLAMP_CLASSIC_CREAM",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // 3 per lexicon qtr sail + 3 per floating lexicon qtr sail
  {
    label: "Large clamp - Lexicon Qtr",
    labelMandarin: "大夹子 #2",
    key: "LARGE_CLAMP_LEXICON_QTR",
    price: 2351.8125,
    bag: "Box 1",
    weight: 0.022,
  },
  // always just 1 for any sail type
  {
    label: "Pulley (shackle)",
    labelMandarin: "滑轮（卸扣）",
    key: "PULLEY_SHACKLE_SINGLE",
    price: 2351.8125,
    bag: "Box 2",
    weight: 0.022,
  },
  // 2 if fixed, none if floating
  {
    label: "Double pulley (shackle)",
    labelMandarin: "双滑轮（卸扣）",
    key: "PULLEY_SHACKLE_DOUBLE",
    price: 5644.35,
    bag: "Box 2",
    weight: 0.04,
  },
  // 1 if floating, none if fixed
  {
    label: "Double pulley (fixed)",
    labelMandarin: "双滑轮（固定）",
    key: "PULLEY_SHACKLE_DOUBLE_WELDED",
    price: 5644.35,
    bag: "Box 2",
    weight: 0.04,
  },
  // 2 if floating, none if fixed
  {
    label: "Triple pulley (shackle)",
    labelMandarin: "三滑轮（卸扣）",
    key: "PULLEY_SHACKLE_TRIPLE",
    price: 6813.9,
    bag: "Box 2",
    weight: 0.06,
  },
  // only if 'parts colour: stainless' at stacking or extending structures. If colour is chosen at stacking end, always add 2. If colour is chosen at extending end, always add 1 more.
  {
    label: "T-mount (stainless)",
    labelMandarin: "T 型安装座（不锈钢）",
    key: "T_MOUNT_STAINLESS",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // Same rule as above, just different parts colour
  {
    label: "T-mount (monument)",
    labelMandarin: "T 型安装座 (深)",
    key: "T_MOUNT_MONUMENT",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // Same rule as above, just different parts colour
  {
    label: "T-mount (lexicon qtr)",
    labelMandarin: "T 型安装座 (白)",
    key: "T_MOUNT_LEXICON_QTR",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // only if 'parts colour: stainless' at stacking and/or extending structures. If parts colour is chosen at stacking end, for posts add 3, for wall add 0, for beam/arch 2, for cantilever 2. If parts colour is chosen at extending end, for posts add 2, for wall add 0, for beam/arch add 1, for frame add 1, for cantilever add 1.
  {
    label: "Twist-mount (stainless)",
    labelMandarin: "旋转安装（不锈钢）",
    key: "TWIST_MOUNT_STAINLESS",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // Same rule as above, just different parts colour
  {
    label: "Twist-mount (monument)",
    labelMandarin: "扭转安装 (深)",
    key: "TWIST_MOUNT_MONUMENT",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // Same rule as above, just different parts colour
  {
    label: "Twist-mount (lexicon qtr)",
    labelMandarin: "扭转安装 (白)",
    key: "TWIST_MOUNT_LEXICON_QTR",
    price: 189.9283136,
    bag: "Starter",
    weight: 0.011,
  },
  // 1
  {
    label: "Plate + 3M tape",
    labelMandarin: "板材+3M胶带",
    key: "PLASTIC_SQUARE",
    price: 378.55,
    bag: "Starter",
  },
  // 1
  {
    label: "Center punch",
    labelMandarin: "中心冲头",
    key: "CENTER_PUNCH",
    price: 1059.9399999999998,
    bag: "Starter",
  },
  // 1 if fixed, 2 if floating (only if they choose black horn cleat)
  {
    label: "Horn cleat (black)",
    labelMandarin: "角锥系绳器 黑色",
    key: "HORN_CLEAT_BLACK",
    price: 983.5573380999999,
    bag: "Box 1",
    weight: 0.05,
  },
  // 1 if fixed, 2 if floating (only if they choose white horn cleat)
  {
    label: "Horn cleat (white)",
    labelMandarin: "角锥系绳器 白色",
    key: "HORN_CLEAT_WHITE",
    price: 983.5573380999999,
    bag: "Box 1",
    weight: 0.05,
  },
  // 1
  {
    label: "Drink holder",
    labelMandarin: "杯托",
    key: "DRINK_HOLDER",
    price: 686.7292501,
    bag: "Box 1",
  },
  // 10
  {
    label: "Hex metal screw",
    labelMandarin: "六角金属螺丝",
    key: "HEX_METAL_SCREW",
    price: 203.49462169999998,
    bag: "Starter",
    weight: 0.0013,
  },
  // 6
  {
    label: "Timber screw",
    labelMandarin: "十字头木螺钉",
    key: "TIMBER_SCREW",
    price: 169.5788514,
    bag: "Box 1",
    weight: 0.0013,
  },
  // 6
  {
    label: "Wall plug",
    labelMandarin: "螺丝墙塞 黄色",
    key: "WALL_PLUG",
    price: 169.5788514,
    bag: "Box 1",
  },
  // 5
  {
    label: "Long metal screw",
    labelMandarin: "长金属螺丝十字头",
    key: "LONG_METAL_SCREW",
    price: 33.915770280000004,
    bag: "Box 1",
    weight: 0.009,
  },
  // (2x per sleeve) + 3
  {
    label: "Button screw",
    labelMandarin: "小平头螺钉",
    key: "BUTTON_SCREW",
    price: 169.5788514,
    bag: "Box 1",
    weight: 0.0013,
  },
  // 1
  {
    label: "Driver bit (phillips)",
    labelMandarin: "起子头（十字槽）",
    key: "DRIVER_BIT_PHILLIPS",
    price: 859.3522875,
    bag: "Starter",
    weight: 0.01,
  },
  // 1
  {
    label: "Driver bit (hex)",
    labelMandarin: "螺丝刀头（六角）",
    key: "DRIVER_BIT_HEX",
    price: 859.3522875,
    bag: "Starter",
  },
  // 2 (monument and deep grey colours only)
  {
    label: "Cap dark (#2)",
    labelMandarin: "深色套 #2",
    key: "CAP_DARK_2",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 2 (windspray and shale grey colours only)
  {
    label: "Cap grey (#2)",
    labelMandarin: "灰色套 #2",
    key: "CAP_GREY_2",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 2 (snow white and classic sand colours only)
  {
    label: "Cap white (#2)",
    labelMandarin: "白色套 #2",
    key: "CAP_WHITE_2",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 2 x (number of sleeves-1), Deep grey and Monument colours only
  {
    label: "Cap dark (#1)",
    labelMandarin: "深色套 #1",
    key: "CAP_DARK_1",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 2 x (number of sleeves-1), Windspray and Shale grey colours only
  {
    label: "Cap grey (#1)",
    labelMandarin: "灰色套 #1",
    key: "CAP_GREY_1",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 2 x (number of sleeves-1), Snow white and Classic sand colours only
  {
    label: "Cap white (#1)",
    labelMandarin: "白色套 #1",
    key: "CAP_WHITE_1",
    price: 220.4525068,
    bag: "Box 1",
    weight: 0.012,
  },
  // 4 (monument and deep grey colours only)
  {
    label: "Cap dark (#3)",
    labelMandarin: "深色套 #3",
    key: "CAP_DARK_3",
    price: 339.1577028,
    bag: "Box 1",
    weight: 0.018,
  },
  // 4 (windspray and shale grey colours only)
  {
    label: "Cap grey (#3)",
    labelMandarin: "灰色套 #3",
    key: "CAP_GREY_3",
    price: 339.1577028,
    bag: "Box 1",
    weight: 0.018,
  },
  // 4 (snow white and classic sand colours only)
  {
    label: "cap white (#3)",
    labelMandarin: "白色套 #3",
    key: "CAP_WHITE_3",
    price: 339.1577028,
    bag: "Box 1",
    weight: 0.018,
  },
  // depends on width (these are A poles that are in all sleeves, except for both the end sleeves). So: number of A poles to make up each width x (number of sleeves -2)
  {
    label: "A1 pole",
    labelMandarin: "管 A1",
    key: "POLE_A1",
    price: 5005.9676930000005,
    bag: "Box 1",
    weight: 0.64,
  },
  // depends on width (these are B poles that are in all sleeves, except for both the end sleeves), So number of B poles to make up each width x (number of sleeves -2)
  {
    label: "B1 pole",
    labelMandarin: "管 B1",
    key: "POLE_B1",
    price: 3510.282224,
    bag: "Box 1",
    weight: 0.4,
  },
  // depends on width (these are C poles that are in all sleeves, except for both the end sleeves), So number of C poles to make up each width x (number of sleeves -2)
  {
    label: "C1 pole",
    labelMandarin: "管 C1",
    key: "POLE_C1",
    price: 3296.612871,
    bag: "Box 1",
    weight: 0.344,
  },
  // ((number poles A, B or C per sleeve/width) - 1) x (number of sleeves - 2)
  {
    label: "D1 joiner",
    labelMandarin: "管 D1",
    key: "POLE_D1",
    price: 549.4354784999999,
    bag: "Box 1",
    weight: 0.054,
  },
  // Only in fixed sails. Depends on width and required A poles. For fixed sails: number of A poles per width x 1. If floating sail then 0
  {
    label: "A2 pole",
    labelMandarin: "管 A2",
    key: "POLE_A2",
    price: 7539.475733,
    bag: "Box 1",
    weight: 1.078,
  },
  // Only in fixed sails. Depends on width and required B poles. For fixed sails: number of B poles per width x 1. If floating sail then 0
  {
    label: "B2 pole",
    labelMandarin: "管 B2",
    key: "POLE_B2",
    price: 5005.9676930000005,
    bag: "Box 1",
    weight: 0.695,
  },
  // Only in fixed sails. Depends on width and required C poles. For fixed sails: number of C poles per width x 1. If floating sail then 0
  {
    label: "C2 pole",
    labelMandarin: "管 C2",
    key: "POLE_C2",
    price: 4364.959635,
    bag: "Box 1",
    weight: 0.605,
  },
  // Number of A2, B2 or C2 poles combined -1. If floating sail then 0
  {
    label: "D2 joiner",
    labelMandarin: "管 D2",
    key: "POLE_D2",
    price: 900.4637008999999,
    bag: "Box 1",
    weight: 0.111,
  },
  // number of A poles to make up width x1. If sail is fixed then this is the total, if floating then the number per width x2.
  {
    label: "A3 pole",
    labelMandarin: "管 A3",
    key: "POLE_A3",
    price: 10683.46764,
    bag: "Box 1",
    weight: 1.291,
  },
  // number of B poles to make up width x1. If sail is fixed then this is the total, if floating then the number per width x2.
  {
    label: "B3 pole",
    labelMandarin: "管 B3",
    key: "POLE_B3",
    price: 8241.532178,
    bag: "Box 1",
    weight: 0.8328,
  },
  // number of C poles to make up width x1. If sail is fixed then this is the total, if floating then the number per width x2.
  {
    label: "C3 pole",
    labelMandarin: "管 C3",
    key: "POLE_C3",
    price: 6867.943482,
    bag: "Box 1",
    weight: 0.7258,
  },
  // If fixed then (total number of poles (A3, B3 or C3) per width) -1.If floating, then double it
  {
    label: "D3 joiner",
    labelMandarin: "管 D3",
    key: "POLE_D3",
    price: 1297.278213,
    bag: "Box 1",
    weight: 0.12,
  },
  // Manufacturer PDF only needs to show the colour, this here is just for you to know how much to charge the customer and so we can track stock. So you will need to create a unique item per colours to track stock, with the quantity per total area (m2) sail formula as: (((sail length in meters - 0.11) x (sail width in meters +0.09)) + ((number of sleeves -2) x (0.135 x (sail width in meters + 0.09))) + (0.14 x (sail width in meters + 0.09)) + (0.29 x (sail width in meters + 0.09)))
  {
    label: "Fabric",
    labelMandarin: "织物",
    key: "FABRIC_COST_PER_METRE_SQUARED",
    price: 8328.1,
    excludeFromManufacturer: true,
    weight: 0.54,
  },
  // ((sail width + 0.15) x (number of sleeves x 3.75)) + ((sail length + 0.2) x 10))
  {
    label: "Thread",
    labelMandarin: "线",
    key: "THREAD",
    price: 63.01916775000001,
    excludeFromManufacturer: true,
    weight: 0.05,
  },
  // 1 if fixed, 2 if floating
  {
    label: "Logo tag",
    labelMandarin: "标志标签",
    key: "LOGO_TAG_STEEL",
    price: 282.21750000000003,
    bag: "Box 1",
    weight: 0.015,
  },
  // 4 for fixed, 8 for floating
  {
    label: "Pop rivets for tag",
    labelMandarin: "吊牌用铆钉",
    key: "POP_RIVETS_TAG",
    price: 0,
    excludeFromManufacturer: true,
  },
  // 4
  {
    label: "Eye bolts",
    labelMandarin: "吊环螺栓",
    key: "EYE_BOLT_SET",
    price: 3028.4,
    bag: "Starter",
  },
  // 1
  {
    label: "Laser measure",
    labelMandarin: "激光测量",
    key: "LASER_MEASURE",
    price: 15141.999999999998,
    bag: "Starter",
  },
  // 1
  {
    label: "Drill bit (4.5mm)",
    labelMandarin: "钻头（4.5mm）",
    key: "DRILL_BITS_4_5MM",
    price: 1505.16,
    bag: "Starter",
    weight: 0.02,
  },
  // 1
  {
    label: "Drill bit (Masonry)",
    labelMandarin: "钻头（砖石）",
    key: "MASONRY_DRILL_BIT",
    price: 573.84225,
    bag: "Starter",
    weight: 0.03,
  },
  // 1
  {
    label: "Drill bit (11mm)",
    labelMandarin: "钻头（11mm）",
    key: "DRILL_BIT_11MM",
    price: 2634.0299999999997,
    bag: "Starter",
    weight: 0.055,
  },
  // 2x length between structures (for sails under 41.5m2)
  {
    label: "4mm cable (1x19)",
    labelMandarin: "4 毫米电缆 (1x19)",
    key: "_4MM_WIRE_ROPE_1X19",
    price: 664.565769,
    excludeFromManufacturer: true,
    weight: 0.0816,
  },
  // 2x length between structures (for sails over 41.5m2)
  {
    label: "5mm wire rope (1x19)",
    labelMandarin: "5mm钢丝绳（1x19）",
    key: "_5MM_WIRE_ROPE_1X19",
    price: 939.7842749,
    excludeFromManufacturer: true,
    weight: 0.1275,
  },
  // Only used when structures are 'posts'. 60% of sail width per end using 'posts' as structures (if both ends are posts, then it would be 120% of total sail width)
  {
    label: "4mm cable (7x7)",
    labelMandarin: "4 毫米电缆 (7x7)",
    key: "_4MM_WIRE_ROPE_7X7",
    price: 664.565769,
    excludeFromManufacturer: true,
    weight: 0.0816,
  },
  // 1 per structural end with posts e.g. if structures are post-post, then 2 of this item
  {
    label: "Tube mount",
    labelMandarin: "管安装",
    key: "TUBE_MOUNT",
    price: 2016.6133680000003,
    excludeFromManufacturer: true,
    weight: 0.03,
  },
  // 2 PER end with posts as structures (always) + 4 per sail that is less than 41.5m2
  {
    label: "Fork terminal (4mm)",
    labelMandarin: "叉形端子（4mm）",
    key: "FORK_TERMINAL_4MM",
    price: 1085.3046490000002,
    excludeFromManufacturer: true,
    weight: 0.05,
  },
  // 4 per sail that is bigger than 41.5m2
  {
    label: "Fork terminal (5mm)",
    labelMandarin: "叉形端子（5mm）",
    key: "FORK_TERMINAL_5MM",
    price: 1741.281975,
    excludeFromManufacturer: true,
    weight: 0.07,
  },
  // if fixed: 2x (number of sleeves -3). If floating 2x (number of sleeves -2)
  {
    label: "Single pulley (pin)",
    labelMandarin: "单滑轮（销轴）",
    key: "_20MM_PULLEY_PIN",
    price: 2351.8125,
    excludeFromManufacturer: true,
    weight: 0.018,
  },
  // always 4
  {
    label: "Single pulley (pivot pin)",
    labelMandarin: "单滑轮（枢轴销）",
    key: "_20MM_PULLEY_PIVOT_PIN",
    price: 3482.66,
    excludeFromManufacturer: true,
    weight: 0.018,
  },
  // 2.2 meters
  {
    label: "Sticky tape",
    labelMandarin: "胶带",
    key: "STICKY_TAPE",
    price: 0,
    excludeFromManufacturer: true,
  },
  // (exc. GST) (charge this to everyone as a flat fee)
  {
    label: "Engineering",
    labelMandarin: "工程",
    key: "ENGINEERING",
    price: 79998,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box A
  {
    label: "International shipping box A",
    labelMandarin: "国际运输箱A",
    key: "INTERNATIONAL_SHIPPING_BOX_A",
    price: 23432.83582,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box B
  {
    label: "International shipping box B",
    labelMandarin: "国际运输箱B",
    key: "INTERNATIONAL_SHIPPING_BOX_B",
    price: 21194.029850000003,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box C
  {
    label: "International shipping box C",
    labelMandarin: "国际运输箱C",
    key: "INTERNATIONAL_SHIPPING_BOX_C",
    price: 18358.20896,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box A
  {
    label: "Domestic shipping box A",
    labelMandarin: "国内运输箱A",
    key: "DOMESTIC_SHIPPING_BOX_A",
    price: 41044.77612,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box B
  {
    label: "Domestic shipping box B",
    labelMandarin: "国内运输箱B",
    key: "DOMESTIC_SHIPPING_BOX_B",
    price: 37313.43284,
    excludeFromManufacturer: true,
  },
  // Should be included if the shipping box is box C
  {
    label: "Domestic shipping box C",
    labelMandarin: "国内运输箱C",
    key: "DOMESTIC_SHIPPING_BOX_C",
    price: 29850.746270000003,
    excludeFromManufacturer: true,
  },
]

module.exports = {
  priceList,
  _6MM_YACHT_CORD_BLACK_ROPE_1: 484.544,
  _6MM_YACHT_CORD_BLACK_ROPE_2: 484.544,
  _6MM_YACHT_CORD_WHITE_ROPE_1: 484.544,
  _6MM_YACHT_CORD_WHITE_ROPE_2: 484.544,
  SMALL_CLAMP_MONUMENT: 1881.4499999999998,
  SMALL_CLAMP_WESTERN_MYALL: 1881.4499999999998,
  SMALL_CLAMP_WINDSPRAY: 1881.4499999999998,
  SMALL_CLAMP_SHALE_GREY: 1881.4499999999998,
  SMALL_CLAMP_CLASSIC_CREAM: 1881.4499999999998,
  SMALL_CLAMP_LEXICON_QTR: 1881.4499999999998,
  Y_SHACKLE: 1145.80305,
  LARGE_CLAMP_MONUMENT: 2351.8125,
  LARGE_CLAMP_WESTERN_MYALL: 2351.8125,
  LARGE_CLAMP_WINDSPRAY: 2351.8125,
  LARGE_CLAMP_SHALE_GREY: 2351.8125,
  LARGE_CLAMP_CLASSIC_CREAM: 2351.8125,
  LARGE_CLAMP_LEXICON_QTR: 2351.8125,
  PULLEY_SHACKLE_SINGLE: 2351.8125,
  PULLEY_SHACKLE_DOUBLE: 5644.35,
  PULLEY_SHACKLE_DOUBLE_WELDED: 5644.35,
  PULLEY_SHACKLE_TRIPLE: 6813.9,
  T_MOUNT_STAINLESS: 189.9283136,
  T_MOUNT_MONUMENT: 189.9283136,
  T_MOUNT_LEXICON_QTR: 189.9283136,
  TWIST_MOUNT_STAINLESS: 189.9283136,
  TWIST_MOUNT_MONUMENT: 189.9283136,
  TWIST_MOUNT_LEXICON_QTR: 189.9283136,
  PLASTIC_SQUARE: 378.55,
  CENTER_PUNCH: 1059.9399999999998,
  HORN_CLEAT_BLACK: 983.5573380999999,
  HORN_CLEAT_WHITE: 983.5573380999999,
  DRINK_HOLDER: 686.7292501,
  HEX_METAL_SCREW: 203.49462169999998,
  TIMBER_SCREW: 169.5788514,
  WALL_PLUG: 169.5788514,
  LONG_METAL_SCREW: 33.915770280000004,
  BUTTON_SCREW: 169.5788514,
  DRIVER_BIT_PHILLIPS: 859.3522875,
  DRIVER_BIT_HEX: 859.3522875,
  CAP_DARK_2: 220.4525068,
  CAP_GREY_2: 220.4525068,
  CAP_WHITE_2: 220.4525068,
  CAP_DARK_1: 220.4525068,
  CAP_GREY_1: 220.4525068,
  CAP_WHITE_1: 220.4525068,
  CAP_DARK_3: 339.1577028,
  CAP_GREY_3: 339.1577028,
  CAP_WHITE_3: 339.1577028,
  POLE_A1: 5005.9676930000005,
  POLE_B1: 3510.282224,
  POLE_C1: 3296.612871,
  POLE_D1: 549.4354784999999,
  POLE_A2: 7539.475733,
  POLE_B2: 5005.9676930000005,
  POLE_C2: 4364.959635,
  POLE_D2: 900.4637008999999,
  POLE_A3: 10683.46764,
  POLE_B3: 8241.532178,
  POLE_C3: 6867.943482,
  POLE_D3: 1297.278213,
  FABRIC_COST_PER_METRE_SQUARED: 8328.1,
  THREAD: 63.01916775000001,
  LOGO_TAG_STEEL: 282.21750000000003,
  POP_RIVETS_TAG: 0,
  EYE_BOLT_SET: 3028.4,
  LASER_MEASURE: 15141.999999999998,
  DRILL_BITS_4_5MM: 1505.16,
  MASONRY_DRILL_BIT: 573.84225,
  DRILL_BIT_11MM: 2634.0299999999997,
  _4MM_WIRE_ROPE_1X19: 664.565769,
  _5MM_WIRE_ROPE_1X19: 939.7842749,
  _4MM_WIRE_ROPE_7X7: 664.565769,
  TUBE_MOUNT: 2016.6133680000003,
  FORK_TERMINAL_4MM: 1085.3046490000002,
  FORK_TERMINAL_5MM: 1741.281975,
  _20MM_PULLEY_PIN: 2351.8125,
  _20MM_PULLEY_PIVOT_PIN: 3482.66,
  STICKY_TAPE: 0,
  ENGINEERING: 79998,
  INTERNATIONAL_SHIPPING_BOX_A: 23432.83582,
  INTERNATIONAL_SHIPPING_BOX_B: 21194.029850000003,
  INTERNATIONAL_SHIPPING_BOX_C: 18358.20896,
  DOMESTIC_SHIPPING_BOX_A: 41044.77612,
  DOMESTIC_SHIPPING_BOX_B: 37313.43284,
  DOMESTIC_SHIPPING_BOX_C: 29850.746270000003,
};
