import * as React from 'react'
import { defaultState } from './reducer'

const { createContext, useContext } = React

export const AttachmentsContext = createContext({ 
  reducerState: defaultState, 
})

export const useAttachmentsContext = () => useContext(AttachmentsContext)
