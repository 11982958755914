

export const persistToLocalStorage = (key, value) => {

  if (typeof localStorage === 'undefined')
    return

  localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = (key) => {
  
  if (typeof localStorage === 'undefined')
    return null

  const valueStr = localStorage.getItem(key)
  if (!valueStr)
    return null
  try {
    var value = JSON.parse(valueStr)
  } catch (error){
    console.error(error)
    return null
  }
  return value
}

export const wrapReducerWithStatePersistence = (key, reducer) => (state, action) => {
  const result = reducer(state, action)
  persistToLocalStorage(key, result)
  return result
}