export const defaultState = {
  opened: false,
  videoUrl: ''
}

const reducer = (state = defaultState, action) => {
  if (action.type === 'OPEN_VIDEO_POPUP') {
    return {
      opened: true,
      videoUrl: action.videoUrl
    }
  }

  if (action.type === 'CLOSE_VIDEO_POPUP') {
    return {
      opened: false,
      videoUrl: ''
    }
  }

  return state
}

export default reducer
