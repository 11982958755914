import * as React from 'react'
import App from './src/components/App'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <App>{element}</App>
}

export const onInitialClientRender = () => {
  const metaTag = document.createElement('meta')
  metaTag.name = 'color-scheme'
  metaTag.content = 'light dark'
  document.head.appendChild(metaTag)
}