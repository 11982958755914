/**
 * Renders the children at the bottom of the DOM.
 */
import * as React from 'react'
import ReactDOM from 'react-dom'

const { 
  useRef, 
  useLayoutEffect,
} = React

const EndOfDOMContainer = props => {

  const { children } = props
  const elementRef = useRef(document.createElement('div'))

  useLayoutEffect(() => {
    document.body.appendChild(elementRef.current)
    document.body.style.overflowY = 'hidden'
    document.body.style.zIndex = '99'
    return () => {
      document.body.removeChild(elementRef.current)
      document.body.style.overflowY = ''
    }
  }, [])

  return ReactDOM.createPortal(
    children,
    elementRef.current,
  )
}

export default EndOfDOMContainer
