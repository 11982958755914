export const openVideoPopup = function(videoUrl){
  return {
    type: 'OPEN_VIDEO_POPUP',
    videoUrl
  }
}

export const closeVideoPopup = function(){
  return { type: 'CLOSE_VIDEO_POPUP' }
}
