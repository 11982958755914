import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  addAttachment,
  updateAttachment,
  clearAttachments,
  removeAttachment,
  setIsLoading,
  setError,
  setAttachmentDeleting,
  deleteAttachment,
  setAttachmentDeleteError,
} from './actions'
import { AttachmentsContext } from './context'

const { useReducer } = React

const AttachmentsLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)

  return <AttachmentsContext.Provider
    value={{
      addAttachment: (attachment) => {
        dispatch(addAttachment(attachment))
      },
      updateAttachment: (attachment) => {
        dispatch(updateAttachment(attachment))
      },
      removeAttachment: (attachment) => {
        dispatch(removeAttachment(attachment))
      },
      clearAttachments: () => {
        dispatch(clearAttachments())
      },
      setIsLoading: (value) => {
        dispatch(setIsLoading(value))
      },
      setError: (error) => {
        dispatch(setError(error))
      },
      setAttachmentDeleting: (attachment, value) => {
        dispatch(setAttachmentDeleting(attachment, value))
      },
      deleteAttachment: (attachment) => {
        dispatch(deleteAttachment(attachment))
      },
      setAttachmentDeleteError: (attachment, error) => {
        dispatch(setAttachmentDeleteError(attachment, error))
      },
      dispatch,
      reducerState,
    }}
  >
    {children}
  </AttachmentsContext.Provider>
}

export default AttachmentsLogic
