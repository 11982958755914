

export const setUser = function(user){
  return {
    type: 'SET_USER',
    user,
  }
}



export const setJwt = function(jwt){
  return {
    type: 'SET_JWT',
    jwt,
  }
}

export const clearUser = function(){
  return {
    type: 'CLEAR_USER',
  }
}