const { roundUpTo2Dp } = require('../../llc/formatting/number.cjs')
const { 
  validateGetShadeSailItemsParams,
  getShadeSailItems, 
  isBoxA,
  isBoxB,
  SELF_INSTALL_DISCOUNT,
} = require('../OrderContentBlock/utilNode.cjs')
var { assert } = require('./../../llc/fs/func.cjs')
var {
  greenZoneShoppingInfo,
  lightBluePostCodes,
  orangePostCodes,
  midBluePostCodes,
  darkGreenPostCodes,
  brownPostCodes,
  pinkPostCodes,
  darkBluePostCodes,
  yellowPostCodes,
  maroonZones,
  postageFlatRateCosts,
} = require('./shippingCosts.cjs')
var { 
  createSailCardTitle, 
} = require('./../SailCard/utilNode.cjs')
var { 
  EXTENDED_WARRANTY_COST, 
  INSTALLATION_KIT_COST,
} = require('./../../util/constants.cjs')

const validateSail = function(sail){
  assert(sail.id, 'Sail requires an id.')
  assert(sail.extendingEndType, 'Sail requires an extendingEndType prop.')
  assert(sail.extendingEndExisting !== undefined, 'Sail requires an extendingEndExisting prop.')
  assert(sail.stackingEndType, 'Sail requires an stackingEndType prop.')
  assert(sail.stackingEndExisting !== undefined, 'Sail requires an stackingEndExisting prop.')
  assert(sail.sailColour, 'Sail requires an sailColour prop.')
  assert(sail.sailLength, 'Sail requires an sailLength prop.')
  assert(sail.sailWidth, 'Sail requires an sailWidth prop.')
  //assert(sail.wireTensioner, 'Sail requires an wireTensioner prop.')
  assert(sail.surfaceToSurface, 'Sail requires an surfaceToSurface prop.')
  assert(sail.selectedUnit, 'Sail requires an selectedUnit prop.')
  assert(sail.maxFabricDrop, 'Sail requires an maxFabricDrop prop.')
  //assert(sail.ropeLengthA, 'Sail requires an ropeLengthA prop.')
  //assert(sail.ropeLengthB, 'Sail requires an ropeLengthB prop.')
  //assert(sail.ropeLengthC, 'Sail requires an ropeLengthC prop.')  
  assert(sail.ropeColour, 'Sail requires an ropeColour prop.')  
  assert(sail.tieOffHornCleatColour, 'Sail requires an tieOffHornCleatColour prop.')  
  assert(sail.sailHeightAboveConcrete, 'Sail requires an sailHeightAboveConcrete prop.') 
}

const validateOrder = function(order){

  assert('extendedWarrantyApplied' in order, 'extendedWarrantyApplied must be set on the order')

}

/**
 * @typedef Address
 * @param {string} city
 * @param {string} country
 * @param {string} line1
 * @param {string} line2
 * @param {string} postcode
 * @param {string} state
 */

/**
 * @typedef ShippingInformation
 * @param {string} title
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} phone
 * @param {Address} address
 */

/**
 * @typedef Order
 * @param {SailOrder[]} sails
 * @param {ShippingInformation} shipping
 * @param {boolean} selfInstallSelected Don't use this prop we're 
 * uncoupling it from the order
 * @param {boolean} extendedWarrantyApplied
 */


const calculateSailTotal = function(sail, options = {}){

  const { skipItemValidation = false, } = options
  
  if (!skipItemValidation)
    validateGetShadeSailItemsParams(sail)
  var items = getShadeSailItems(sail)
  
  var costInDollars = items.reduce((pre, cur) => {
    if (Number.isNaN(Number(cur.price)))
      throw new Error(`Invalid price ${cur.price} for ${cur.key}.`)
    return pre + cur.price
  }, 0)

  // Price is in cents, round to avoid any JS rounding errors
  return Math.round(roundUpTo2Dp(costInDollars / 100) * 100)

}


const noGoPostCodes = [
  '2898-2899', '6798-6799', '7151',
]

const getBoxCostForPostCode = boxCostKey => postCode => {
  var groups = [
    greenZoneShoppingInfo,
    lightBluePostCodes,
    orangePostCodes,
    midBluePostCodes,
    darkGreenPostCodes,
    brownPostCodes,
    pinkPostCodes,
    darkBluePostCodes,
    yellowPostCodes,
    maroonZones,
  ]


  var postCodeCostHashmap = {}

  for (var group of groups){

    var postCodeStrings = group.postCodes
    var postCodes = postCodeStrings.map(unfurlPostCodesFromString).flat()
    for (var localPostCode of postCodes){
      // Could optimise by not recomputing this hashmap
      switch(boxCostKey){
        case 'boxACost':
          var costValue = group.boxACost
          break
        case 'boxBCost':
          var costValue = group.boxBCost
          break
        case 'boxCCost':
          var costValue = group.boxCCost
          break
        default:
          throw new Error(`Unknown key ${boxCostKey}.`)
      }

      postCodeCostHashmap[localPostCode] = costValue
      
      if (postCode == localPostCode)
        return costValue
    }

  }

  return null
}

const getBoxACostForPostCode = postCode => {
  return getBoxCostForPostCode('boxACost')(postCode)
}

const getBoxBCostForPostCode = postCode => {
  return getBoxCostForPostCode('boxBCost')(postCode)
}

const getBoxCCostForPostCode = postCode => {
  return getBoxCostForPostCode('boxCCost')(postCode)
}

const isNoGoPostCode = postCode => {
  // TODO Could optimise
  var unfurledNoGoPostCodes = noGoPostCodes.map(unfurlPostCodesFromString).flat()
  return unfurledNoGoPostCodes.includes(String(postCode))
}

const unfurlPostCodesFromString = postCodeString => {
  postCodeString = postCodeString.replace(/\s+/g, '')
  var postCodesTemp = postCodeString.split('-')
  if (postCodesTemp.length == 1){
    return postCodesTemp
  }

  if (postCodesTemp.length != 2)
    throw new Error(`Invalid post code range ${postCodeString}.`)

  // Do a quick sanity check so that we don't get stuck in an infinite loop
  var tempA = Number(postCodesTemp[0])
  var tempB = Number(postCodesTemp[1])

  if (Number.isNaN(tempA)){
    throw new Error(`Invalid postcode ${tempA}`)
  }

  if (Number.isNaN(tempB)){
    throw new Error(`Invalid postcode ${tempB}`)
  }

  if (tempA > tempB){
    throw new Error(`Invalid postcode range.`)
  }

  if ((tempB - tempA) > 10000){
    throw new Error('Range too large.')
  }

  var temp = tempA
  var postCodes = []
  do {
    postCodes.push(String(temp))
    temp++
  } while (temp <= tempB)
  

  return postCodes
}

/**
 * @typedef FlatRateShippingCosts
 * @property {number} boxACost
 * @property {number} boxBCost
 * @property {number} boxCCost
 */

/**
 * @param {FlatRateShippingCosts} flatRateShippingCosts Must be provided
 * as depending on if the self install discount is applied, different 
 * base postage costs are included in the sail costs
 */
const calculateShippingTotal = function(params){
  // Calculate sail area size
  const {
    sails = [],
    postCode,
  } = params

  // Update 19th July we're adding back in excess shipping costs.

  if (!postCode)
    throw new Error('postCode is required.')

  if (isNoGoPostCode(postCode))
    throw new Error(`Cannot ship to post code ${postCode}.`)


  // Box A: Sails above 48m2 - (we charge everyone a flat $275)
  // Box B: Sails between 19.8m2-48m2 - (we charge everyone a flat $260)
  // Box C: Sails below 19.8m2 - (we charge everyone a flat $205)

  var cost = sails.reduce((cumTotal, sail, i) => {

    const {
      sailWidth,
      sailLength,
    } = sail

    //console.log('calculateShippingTotal#area', area)
    //console.log('calculateShippingTotal#Math.pow(48000, 2)', Math.pow(48000, 2))

    if (isBoxA({ sailWidth, sailLength })){

      var shippingCost = getBoxACostForPostCode(postCode)

    } else if (isBoxB({ sailWidth, sailLength })){
      var shippingCost = getBoxBCostForPostCode(postCode)
    } else {
      var shippingCost = getBoxCCostForPostCode(postCode)

    }
    //console.log('calculateShippingTotal#i#builtInCost', builtInCost)
    //console.log('calculateShippingTotal#i#shippingCost', shippingCost)


    var excessShippingCost = shippingCost
    //console.log('calculateShippingTotal#i#excessShippingCost', excessShippingCost)

    return cumTotal + Math.max(0, excessShippingCost)
    
  }, 0)

  return cost * 100

}

/**
 * Calculates the order total. Returns a list of invoice line items that are GST
 * exclusive. This is the total that is before any discounts are applied.
 * There will be a line item for each sail.
 * @param {Order} order
 */
const calculateOrderTotal = function(order){
 
  var lineItems = []

  order.sails.forEach((sail) => { 

    var description = 'Sail: ' + createSailCardTitle({
      colourName: sail.sailColour.name,
      sailWidth: sail.sailWidth,
      sailLength: sail.sailLength,
      selectedUnit: sail.selectedUnit,
    })

    var total = calculateSailTotal(sail)

    lineItems.push({
      description,
      unitAmount: total,
      quantity: 1,
      total,
      code: 'SAIL',
    })
  }, 0)

  // Commenting out as we removed shipping from the order.
  /*var postcode = order?.shipping?.address?.postcode
  if (postcode){
    // The flat rate on postage costs is included in the sail price, so we have
    // to normalise it based on the discount applied
    var normalisedPostageFlatRateCosts = Object.assign({}, postageFlatRateCosts)
    if (order.selfInstallSelected){
      normalisedPostageFlatRateCosts.boxACost *= (1 - SELF_INSTALL_DISCOUNT) 
      normalisedPostageFlatRateCosts.boxBCost *= (1 - SELF_INSTALL_DISCOUNT) 
      normalisedPostageFlatRateCosts.boxCCost *= (1 - SELF_INSTALL_DISCOUNT) 
    }

    var shippingCosts = calculateShippingTotal({
      sails: order.sails,
      postCode: postcode,
      flatRateShippingCosts: normalisedPostageFlatRateCosts,
    })
    lineItems.push({
      description: 'Shipping',
      total: Math.round(shippingCosts),
      key: 'shipping',
    })
  }*/

  if (order.extendedWarrantyApplied){
    lineItems.push({
      description: 'Extended warranty',
      unitAmount: EXTENDED_WARRANTY_COST,
      quantity: order.sails.length,
      total: EXTENDED_WARRANTY_COST * order.sails.length,
      code: 'EXTENDED_WARRANTY',
    })
  }

  if (!order.noInstallationKitCode){
    lineItems.push({
      description: 'Install tool kit bond',
      unitAmount: INSTALLATION_KIT_COST,
      quantity: 1,
      total: INSTALLATION_KIT_COST,
      code: 'INSTALLATION_KIT',
    })
  }
    
  return {
    items: lineItems,
  }
}


module.exports = {
  validateSail,
  calculateSailTotal,
  calculateShippingTotal,
  calculateOrderTotal,
  validateOrder,
  getBoxACostForPostCode,
  getBoxBCostForPostCode,
  getBoxCCostForPostCode,
  unfurlPostCodesFromString,
  isNoGoPostCode,
}

