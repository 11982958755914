var { formatNumber } = require("../../llc/formatting/string.cjs")
var { getUnitRatioToMM } = require("../OrderContentBlock/utilNode.cjs")

const createSailCardTitle = (params) => {

  const {
    colourName,
    sailWidth,
    sailLength,
    selectedUnit,
    separator = '-'
  } = params

  let widthStr = sailWidth
    ? formatNumber(sailWidth / getUnitRatioToMM(selectedUnit))
    : ''

  let lengthStr = sailLength
    ? formatNumber(sailLength / getUnitRatioToMM(selectedUnit))
    : ''

  return `
    ${colourName || ''} 
    ${separator} ${widthStr}${selectedUnit || ''} 
    x ${lengthStr}${selectedUnit || ''}
  `.replace(/\s+/g, ' ').trim()
}

module.exports = {
  createSailCardTitle,
}
