



export const getArrayOfSearchParamKeys = searchParams => {
  var keys = []
  for (var key of searchParams.keys()){
    keys.push(key)
  }
  return keys
}



/**
 * Given a YouTube URL or a Vimeo URL it returns the embedded version
 * that can be used as the url from an iFrame.
 * @param {string} urlString
 * @returns {string}
 */
export const formatUrlToEmbeddedUrl = function(urlString, options = {}){

  const {
    autoplay = true,
  } = options

  var embeddedYouTubeUrlRegex = /https:\/\/www.youtube.com\/embed\/[\s\w-]+/i

  if (embeddedYouTubeUrlRegex.test(urlString)){

    var url = new URL(urlString)
    var list = url.searchParams.get('list')
    for (var key of getArrayOfSearchParamKeys(url.searchParams)){
      url.searchParams.delete(key)
    }
    if (list){
      url.searchParams.set('list', list)
    }
    if (autoplay){
      url.searchParams.set('autoplay', '1')
    }
    return url.toString()
  }

  var regularYouTubeUrlRegex = /https:\/\/www.youtube.com\/watch/i
  if (regularYouTubeUrlRegex.test(urlString)){

    var url = new URL(urlString)
    var videoKey = url.searchParams.get('v')
    var list = url.searchParams.get('list')
    if (!videoKey)
      throw new Error('URL is missing video key.')
    // Clean URL query string
    /*for (var key of getArrayOfSearchParamKeys(url.searchParams)){
      url.searchParams.delete(key)
    }*/

    var newUrl = new URL(`https://www.youtube.com/embed/${videoKey}`)
    if (autoplay){
      newUrl.searchParams.set('autoplay', '1')
    }
    return newUrl.toString()
  }

  var embeddedVimeoUrlRegex = /https:\/\/player.vimeo.com\/video\/[\s\w-]+/i
  if (embeddedVimeoUrlRegex.test(urlString)){

    var url = new URL(urlString)
    for (var key of getArrayOfSearchParamKeys(url.searchParams)){
      url.searchParams.delete(key)
    }
    if (autoplay){
      url.searchParams.set('autoplay', '1')
    }
    return url.toString()
  }

  var regularVimeoUrlRegex = /https:\/\/vimeo.com\/([\s\w-]+)/i
  
  var vimeoMatch = urlString.match(regularVimeoUrlRegex)
  if (vimeoMatch){
    var newUrl = new URL(`https://player.vimeo.com/video/${vimeoMatch[1]}`)
    if (autoplay){
      newUrl.searchParams.set('autoplay', '1')
    }
    return newUrl.toString()
  }

  return urlString
}
