export const defaultState = {}

const reducer = (state = defaultState, action) => {
  switch (action.type){
  case 'ADD_NOTE':
  case 'SET_NOTE':
  case 'UPDATE_NOTE':
    var { note } = action
    var existingNote = state[note.id]
    // Check if anything has changed
    if (JSON.stringify(existingNote) == JSON.stringify(note))
      return state
    state = Object.assign({}, state)
    state[note.id] = Object.assign({}, note)
    return state
  case 'DELETE_NOTE': 
    var { note } = action
    var existingNote = state[note.id]
    // Check if anything has changed
    if (!existingNote)
      return state

    state = Object.assign({}, state)
    delete state[note.id]
    return state
  case 'SET_NOTE_LOADING':
    var { 
      noteId,
      value, 
    } = action
    
    var existingNote = state[noteId]
    if (!existingNote){
      var newState = Object.assign({}, state)
      newState[noteId] = { isLoading: value }
      return newState
    }
      
    // Nothing has changed
    if (existingNote.isLoading == value)
      return state

    var existingNoteClone = Object.assign(
      {}, 
      existingNote,
      { isLoading: value, }
    )
    var newState = Object.assign({}, state)
    newState[noteId] = existingNoteClone
    return newState
  case 'SET_NOTE_ERROR':
    var { noteId, error } = action
    var existingNote = state[noteId]
    if (!existingNote)
      return state
      
    var existingNoteClone = Object.assign(
      {}, 
      existingNote,
      { error }
    )
    var newState = Object.assign({}, state)
    newState[noteId] = existingNoteClone
    return newState
  case 'SET_NOTE_UPDATING':
    var { 
      noteId,
      value, 
    } = action
      
    var existingNote = state[noteId]
    if (!existingNote)
      return state
      // Nothing has changed
    if (existingNote.isUpdating == value)
      return state

    var existingNoteClone = Object.assign(
      {}, 
      existingNote,
      { isUpdating: value, }
    )
    var newState = Object.assign({}, state)
    newState[noteId] = existingNoteClone
    return newState
    
  case 'SET_NOTE_UPDATE_ERROR':
    var { noteId, error } = action
    var existingNote = state[noteId]
    if (!existingNote)
      return state
      
    var existingNoteClone = Object.assign(
      {}, 
      existingNote,
      { updateError: error }
    )
    var newState = Object.assign({}, state)
    newState[noteId] = existingNoteClone
    return newState
  default: 
  }

  return state
}

export default reducer
