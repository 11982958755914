

/**
 * Format a string into a currency string.
 */
const formatNumber = (str, options = {}) => {
  const { padDecimal = true } = options

  if (str == null) str = ''
  str = String(str).replace(/[^-0-9.]/g, '')

  var decimalIndex = str.indexOf('.')
  if (decimalIndex != -1) {
    var wholePart = str.slice(0, decimalIndex)
    var fractionalPart = str.slice(decimalIndex + 1)
    fractionalPart = fractionalPart.replace(/\./g, '')
    fractionalPart = fractionalPart.slice(0, 2)
  } else {
    var wholePart = str
    var fractionalPart = ''
  }

  if (wholePart.startsWith('-')) {
    var isNegative = true
  } else {
    var isNegative = false
  }
  // Remove the negative sign
  wholePart = wholePart.replace(/[^0-9.]/g, '')

  // Add commas to whole number
  var i = 0
  var newWholeParts = []
  while (i < wholePart.length) {
    var start = Math.max(wholePart.length - i - 3, 0)
    var end = wholePart.length - i

    newWholeParts.splice(0, 0, wholePart.slice(start, end))

    i += 3
  }

  var newStr = newWholeParts.join(',')

  if (isNegative) newStr = `-${newStr}`

  var final = `${newStr}`
  if (decimalIndex != -1) final += '.'
  if (fractionalPart) {
    if (padDecimal) {
      while (fractionalPart.length < 2) {
        fractionalPart += '0'
      }
    }
    final += fractionalPart
  }

  return final
}

module.exports = {
  formatNumber,
}
