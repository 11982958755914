const { 
  BOX_A_INCLUDED_DOMESTIC_SHIPPING_COST, 
  BOX_B_INCLUDED_DOMESTIC_SHIPPING_COST, 
  BOX_C_INCLUDED_DOMESTIC_SHIPPING_COST, 
} = require('./../OrderContentBlock/priceListNode.cjs')


/**
 * Each sail will have this shipping cost built in, so this value counts against
 * the true shipping cost and if there is a positive difference then
 * that will be shown to the user as an excess shipping cost.
 * This value is in dollars, not cents
 * TODO Shipping values should be in cents to align with other costs being in
 * cents
 */
const postageFlatRateCosts = {
  boxACost: BOX_A_INCLUDED_DOMESTIC_SHIPPING_COST / 100,
  boxBCost: BOX_B_INCLUDED_DOMESTIC_SHIPPING_COST / 100,
  boxCCost: BOX_C_INCLUDED_DOMESTIC_SHIPPING_COST / 100,
}



const greenZoneShoppingInfo = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['3000-3097', '3101-3138', '3140-3207', '3335-3340', '3427-3429', '3750-3753', '3765', '3787', '3800-3810', '3930', '3975-3976', '8001-8012', '8045', '8051', '8066-8071', '8102', '8111-8205', '8438', '8511', '8785', '9999'],
}

const lightBluePostCodes = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['200', '2582', '2600-2620', '2900-2906', '2911-2914', '1100', '1215-1215', '1220', '1225', '1230-1231', '1235', '1239-1240', '1291', '1300', '1335', '1340', '1350', '1355', '1360', '1405', '1435', '1445', '1450', '1455', '1460', '1465-1466', '1470', '1475', '1480-1481', '1485', '1490', '1495', '1499', '1515', '1560', '1565', '1570', '1585', '1590', '1595', '1602', '1630', '1635', '1640', '1655', '1660-1685', '1700', '1710-1730', '1750-1765', '1790', '1800-1811', '1825', '1835', '1851-1885', '1890-1891', '2000-2080', '2084-2156', '2158', '2160-2234', '2555-2559', '2563-2567', '2745-2752', '2759-2774', '2776-2777', '2890-2891', '4000-4022', '4029-4037', '4051-4078', '4101-4133', '4151-4179', '4205', '4300-4304', '4500-4506', '4508-4511', '4520', '9000-9002', '9005-9007', '9009-9015', '9464', '5000-5025', '5031-5052', '5061-5076', '5081-5098', '5106-5127', '5131-5133', '5150', '5156', '5158-5170', '5173-5174', '5800-5800', '5810', '5839', '5842', '5950', '800-801', '804', '810-814', '820-821', '828-831', '851', '861', '871', '906', '907', '909-909', '2640-2641', '2708', '2650', '2680-2681', '2705', '5134-5144', '5151-5155', '5157', '5171-5172', '5201', '5245', '5351', '5352', '5355'],
}


const orangePostCodes = {
  boxACost: 300,
  boxBCost: 185,
  boxCCost: 105,
  postCodes: ['6000-6038', '6050-6081', '6090-6090', '6100-6112', '6147-6176', '6180-6182', '6209-6210', '6800-6800', '6809-6809', '6827-6831', '6837-6850', '6865-6865', '6872-6872', '6892- 6892', '6900-6939', '6941-6979', '6981-6997', '803', '815', '822', '832-841', '845-850', '852-854', '860', '862', '870', '872-875', '880-881', '885-886'],
}

const midBluePostCodes = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['2081-2083', '2157', '2159', '2753-2754', '2756-2758', '2775', '2786', '2250-2252', '2256-2263', '2264', '2267', '2278-2310', '2315-2319', '2322', '2324', '2348-2348', '2442', '2624', '2320', '2325', '2335', '2450-2452', '2454-2456', '2500-2508', '2515-2534', '2560', '2568-2574', '2778-2785', '2800', '2850', '2485-2488', '2490', '4209-4228', '4230', '9726', '4025', '4183-4184', '4207-4208', '4270-4275', '4280', '4285-4287', '4507', '4512-4519', '4550-4575', '4305-4307', '4346', '4521', '4375-4376', '4380-4381', '4655', '4740', '4750-4751', '4810-4812', '4814-4815', '4817-4818', '4343-4345', '4347', '4350', '4400', '4700-4701', '4703', '4868-4870', '4878-4879', '7020-7024', '7026-7027', '7054', '7109-7150', '7155-7163', '7171', '7173-7216', '7255-7257', '7330-7331', '7466-7470'],
}

const darkGreenPostCodes = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['7000-7019', '7025', '7030', '7050-7053', '7055', '7170', '7172', '3099', '3139', '3754-3755', '3757', '3759-3761', '3763', '3766-3786', '3788-3799', '3211-3230', '3331', '3341', '3430-3442', '3458', '3756', '3758', '3762', '3764', '3350', '3353-3357', '3453', '3463', '3515', '3523', '3550-3551', '3555-3557', '3496-3500', '3505', '3629-3631', '3689-3690', '3910-3920', '3926-3929', '3931-3944', '3977-3978', '3980-3981'],
}

const brownPostCodes = {
  boxACost: 335,
  boxBCost: 195,
  boxCCost: 195,
  postCodes: ['6041-6044', '6082-6084', '6501', '6121-6126', '6207-6208', '6211-6214', '6285'],
}

const pinkPostCodes = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['7248-7254', '7258-7277', '7290-7292', '7300-7325'],
}

const darkBluePostCodes = {
  boxACost: 115,
  boxBCost: 85,
  boxCCost: 85,
  postCodes: ['2265', '2311-2312', '2321', '2323', '2326-2334', '2336-2347', '2350-2361', '2365', '2369-2372', '2379-2382', '2386-2390', '2395-2431', '2439-2441', '2443-2449', '2453', '2460-2484', '2489', '2535-2551', '2575-2581', '2583-2594', '2621-2623', '2625-2633', '2642-2647', '2649', '2651-2678', '2700-2703', '2706-2707', '2710-2714', '2716', '2720-2730', '2787-2799', '2803-2810', '2817-2849', '2852', '2864-2879', '4309-4314', '4340-4342', '4352-4365', '4370-4374','4377-4378', '4382-4390', '4401-4428', '4580-4581', '4600-4601', '4605-4615', '4620-4630', '4650-4650', '4659-4662', '4670-4680', '4454-4455', '4461-4498', '4694-4699', '4702-4702', '4704-4739', '4741-4746', '4753-4757', '4798-4802', '4804-4805', '4803-4803', '4806-4809', '4813-4813', '4816-4816', '4819-4830', '4849-4865', '4871-4877', '4880-4895', '2880', '5202-5204', '5210-5214', '5220-5223', '5231-5244', '5250-5280', '5290-5311', '5320-5322', '5330-5333', '5340-5346', '5350', '5353-5354', '5356-5357', '5360', '5371-5374', '5381', '5400-5401', '5410-5422', '5431-5434', '5440', '5451-5455', '5460-5464', '5470-5473', '5480-5485', '5490-5495', '5501-5502', '5510', '5520-5523', '5540', '5550-5560', '5570-5583', '5600-5611', '5630-5633', '5640-5642', '5650-5655', '5660-5661', '5670-5671', '5680', '5690', '5700-5701', '5710-5725', '5730-5734'],
}

const yellowPostCodes = {
  boxACost: 0,
  boxBCost: 0,
  boxCCost: 0,
  postCodes: ['2648', '2715', '2717', '2731-2739', '3231-3243', '3249-3254', '3260-3294', '3300-3330', '3332-3334', '3342-3345', '3351-3352', '3358-3364', '3370-3402', '3407-3424', '3444-3451', '3460-3462', '3464-3494', '3501-3502', '3506-3512', '3516-3522', '3525-3533', '3537-3549', '3552-3554', '3558-3599', '3607-3624', '3632-3649', '3658-3688', '3691-3749', '3812-3825', '3831-3835', '3840-3904', '3909', '3921-3925', '3945-3946', '3950-3971', '3979', '3984', '3987-3996'],
}

const maroonZones = {
  boxACost: 600,
  boxBCost: 370,
  boxCCost: 200,
  postCodes: ['6215-6244', '6251-6262', '6271-6284', '6286-6290', '6302-6375', '6383-6386', '6390-6452', '6460-6490', '6502-6537', '6556-6575', '6603-6616', '6620-6646', '6701-6733', '6740-6743', '6751-6765', '6770'],
}

module.exports = {
  postageFlatRateCosts,
  greenZoneShoppingInfo,
  lightBluePostCodes,
  orangePostCodes,
  midBluePostCodes,
  darkGreenPostCodes,
  brownPostCodes,
  pinkPostCodes,
  darkBluePostCodes,
  yellowPostCodes,
  maroonZones,
}